.select-wrapper {
  width: 100%;
  margin-bottom: 20px;

  .select-container {
    outline: none;

    .select__single-value {
      font-size: 13px;
      color: #333D47;
      margin-top: 6px;
    }

    .select__multi-value {
      background: #FB5830;
      border-radius: 100px;
      height: 23px;
      margin: 3px 13px 3px 0;

      .select__multi-value__remove {
        color: #ffffff;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.2);
        width: 17px;
        height: 17px;
        position: relative;
        left: -3px;
        top: 3px;
        margin-left: 28px;
        transition: color .3s, background-color .3s;

        &:hover {
          cursor: pointer;
          color: #FB5830;
          background-color: #F7F8F9;
        }
      }

      .select__multi-value__label {
        color: #FFFFFF;
        font-size: 13px;
        line-height: 17px;
        padding: 2px 3px 3px 10px;
      }
    }

    .select__option {
      background-color: transparent;
      padding: 11px 10px;
      margin: 0 8px;
      width: auto;
      border-radius: 6px;

      &:hover {
        background: #F5F6F7;
      }

      &--is-selected {
        color: #414549;
        background: #F5F6F7;
      }
    }

    .select__placeholder {
      color: #707E8B;
      font-size: 13px;
    }

    .select__menu {
      margin-top: 1px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      width: 279px;
      &-list--is-multi {
        .select__option--is-selected {
          background: transparent;
        }
      }
    }

    .select__control {
      border: none;
      background: #F1F3F4;
      border-radius: 2px;
      outline: none !important;
      box-shadow: none;
      min-height: 36px;
      position: relative;
      max-height: 250px;
      overflow-y: auto;


      .select__value-container {
        padding: 0 30px 0 13px;
      }

      .indicator {
        position: absolute;
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        border-top: 5px solid;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        content: '';
        right: 16px;
        top: 16px;
        color: #707E8B;
      }

      &--menu-is-open {
        background: #FFF;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

        .indicator {
          border-top: none;
          border-bottom: 5px solid;
        }
      }

    }
  }

  .error {
    .select__control {
      &:after {
        content: '';
        bottom: 0;
        position: absolute;
        width: calc(100% - 8px);
        left: 4px;
        height: 2px;
        border-radius: 2px 2px 0 0;
        background-color: #FF3C3C;
      }
    }
  }
}
