.emr-sidebar {
  margin-top: 30px;
  display: flex;
  justify-content: center;

  &__button.btn-component {
    width: 172px;
    font-size: 14px;
    line-height: 16px;
  }
}
