.veradigm-container-page{
  display: flex;
  padding: 20px;
  button{
    &:first-of-type{
      margin-right: 20px;
    }
  }

}
