@import "src/styles/colors";

.payment-step {
  width: 100%;
  max-width: 500px;

  .custom-input-container{
    margin-bottom: 12px;
  }

  &_informed-options {
    margin-top: 24px;
    &_wrapper {
      margin-bottom: 24px;
      margin-top: 12px;
      display: flex;
      grid-gap: 24px;
    }
  }

  .card-container {
    margin-left: 0;

    svg path {
      fill: $warning;
    }
  }
}
