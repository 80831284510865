.popup-component-common-expand-content {
  display: flex;
  flex-direction: column;
  .header-expand{
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    .block-title{
      font-size: 28px;
      line-height: 32px;
      display: flex;
      align-items: center;
    }
    .expand{

    }
  }
  .after-header{
    height: 40%;
    display: flex;
    .left-block{
      justify-content: space-between;
      display: flex;
      width: 100%;
      .time-expand_popup {
        margin-right: 20px
      }
    }
    .right-block{
      justify-content: flex-end;
      display: flex;
      width: 100%
    }
  }
  .table-expand{
    height: 60%;
  }

}
