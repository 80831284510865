@import "src/styles/colors";
.patient-info-container {

  .links-from-list-symptoms{
    line-height: 24px;
    letter-spacing: 0.15px;
    a{
      color: $primary70 !important;
    }
  }

  &_verification-6-month {
    margin-top: 20px;

    span.typography-p{
      line-height: 24px;
    }
    label.container-radio-button:first-of-type{
      margin-top: 30px;
    }
    &_email {
      margin-top: 40px;
    }

    &_address-top {
      margin-top: 20px;
    }

    &_address-bottom {
      display: inline-block;
      margin-bottom: 50px;
    }

    &_text {
      margin-bottom: 30px;
      display: inline-block;
    }

    .container-radio-button {
      &:last-of-type {
        margin-top: 23px;
      }
    }
  }

  &_verification-age {
    margin-top: 30px;
    display: inline-block;
    margin-bottom: 30px;
    display: block;
  }

  &_asking-container-buttons {
    display: flex;

    button:first-child {
      margin-right: 20px;
    }

  }

  &_block-row {
    display: flex;

    .card-container_info-cloud{
      width: 100%;
    }
    .card-info__message_info-cloud{
      top: calc(100% - 2px);
    }

    &_radio-buttons {
      display: flex;
      height: 56px;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 25px;
      width: 100%;

      &_container {
        display: flex;

        label {
          text-transform: capitalize;
          &:last-of-type {
            margin-left: 45px;
          }
        }
      }
    }

    & > div:first-child {
      margin-right: 10px;
    }

    &.half-width {
      width: calc(50% + 5px);
    }

    .drop-down-wrapper_label {
      white-space: nowrap;
      display: block;
      width: calc(100% - 55px);
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }

  .custom-input-container {
    margin-bottom: 35px;
  }

  &_checkbox {

  }
}

.address-full-width div:first-child {
  margin-right: 0
}

.list-patients{
  &_buttons-actions{
    display: flex;
    justify-content: space-between;
    &_add-patient{
      margin-top: 30px;
      min-width: 154px;
    }
    .app-container-request_wrapper_right-panel_navigation-buttons{
    }
  }

  &_list{
    max-height: 65vh;
    overflow: auto;

    .card-patient{
      margin-top: 12px;
    }
  }
  &_buttons-choice{
    margin-top: 40px;
    display: flex;

    button{
      &:first-of-type{
        margin-right: 20px;
      }
    }
  }
}

.card-patient{
  display: flex;
  padding: 23px;
  background: #FFFFFF;
  border: 1px solid #DADCE0;
  box-sizing: border-box;
  border-radius: 2px;

  .container-radio-button{
    color: #777777 !important;
    padding-left: 40px;
  }

  &--selected {
    box-shadow: 0 1px 8px rgba(14, 67, 109, 0.16);
  }

  &:hover{
    background-color: $primary10;
    cursor: pointer;
    svg{
      box-shadow: 0 0 10px $primary80;
      border-radius: 50%;
    }
  }

  &_active {
    svg {
      box-shadow: 0 0 10px $primary80;
      border-radius: 50%;
    }
  }

  &_info{
    display: flex;
    &_img{
      height: 64px;
      width: 64px;
      border-radius: 50%;
      margin-right: 18px;
    }
    &_data{
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      span:not(:first-of-type) {
        margin-top: 8px;
      }
    }
  }

}
