.login-form-code-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_header{
    width: 100%;
    display: flex;
    justify-content: space-between;

    &_titles{
      display: flex;
      flex-direction: column;
    }
  }
  &_labels{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  &_img{
    margin-top: 30px;
  }

  &_code{
    margin-top: 40px;
  }
  &_mobile{
    margin-top: 40px;
  }
  &_buttons{
    display: flex;
    margin-top: 40px;

    button{
      &:first-of-type{
        margin-right: 30px;
      }
    }
  }
}

.login-form-code-overlay{
  z-index: 1000 !important;
}

@media (max-width: 560px) {
  .login-form-code-content {
    width: 80% !important;

    .login__code-container{
      input{
        font-size: 20px;
        width: 25px;
        margin-right: 0;
      }
    }

    .container-input-flag {
      width: 100%;
      .select-wrapper {
        width: 30%;
      }
      .container-input-flag_input {
        width: 150px;
      }
    }
    .login-form-code-content_buttons button:first-of-type {
      margin-right: 5px;
    }
  }

}
