@import "src/styles/colors";
.details-of-request {
  height: 50%;
  border-bottom: 1px solid $core30;
  padding-bottom: 30px;
  padding-right: 32px;
  padding-top: 30px;
overflow: auto;

  .confirmation-delete-add-info-content {
    .popup-component-common-content_header_wrapper-titles {
      padding-bottom: 20px;
    }
  }

  &_info{
    margin-top: 22px;
    display: flex;
    flex-direction: column;
    position: relative;

    span{
      &:first-of-type{
        margin-bottom: 5px;
      }
    }

    .icon-component{
      position: absolute;
      right: 0;

      &:first-of-type{
        right: 25px;
      }
    }
  }

  .container-title-value {
    margin-top: 12px;
  }

  .container-map{
    margin-top: 16px;
  }

  &_additional{
    border-radius: 4px;
    align-items: center;
    display: flex;
    margin-top: 30px;
    font-size: 14px ;
    justify-content: center;

    .icon-component {
      margin-right: 8px;
      svg {
        width: 14px;
        height: 14px;
      }
    }
  }
}


.container-title-value {
  span {
    &:first-of-type {
      width: 40px;
    }
  }
}

.additional-information-text-content{
  margin-top: 30px;
  .popup-component-common-content_body{
    padding-top: 20px;
    padding-bottom: 15px  ;
  }
  .textarea-common-component_textarea{
    margin-bottom: 0;
    margin-top: 10px;
  }
}
