@import "src/styles/colors";
.container-panel-filter{
  display: flex;
  justify-content: space-between;
  align-items: center;
  &_left-part{
    display: flex;
  }

  &_button-add{
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 20px;
    color: #357DBD;
    padding: 0;
    background: none;
    border: none;
    font-weight:500;

    &_icon{
      margin-right: 7px;
    }
  }

  &_search{
    width: 400px;
    margin-right: 24px;
  }

  &_filters-icon{
    margin-left: 24px;
    margin-right: 13px;
  }
  &_trigger-button{

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 110px;
    padding: 0 15px;

    height: 36px;
    border: 1px solid $core40;
    box-sizing: border-box;
    border-radius: 50px;
    >span{
      color: $core70 !important;
    }
  }
  &_trigger-button-isValue, &_trigger-button{
    cursor: pointer;
    outline: none;
    background: none;
  }
  &_trigger-button-isValue{
    border: none;
  }
}
