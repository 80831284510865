.doctor-page-personal-info{
  width: 62%;
  margin: 0 auto;

  &_title{
    display: flex;
    justify-content: center;
    text-align: center;
    margin: 40px 0 43px 0;
  }
  &_title-block {
    display: flex;
    justify-content: space-between;

    &_container-radio{
      flex-direction: column;
      margin-bottom: 25px;

      &_title{
        margin-bottom: 20px;
      }
      &_radio-buttons {
        display: flex;

        label {
          text-transform: capitalize;
          &:last-of-type {
            margin-left: 45px;
          }
        }
      }
    }

    .custom-input-container{
      &:first-of-type {
        margin-right: 10px;
      }
    }
  }

  &_title-btn {
    display: flex;
    margin-left: auto;
    justify-content: space-between;
    width: 278px;
    padding-bottom: 32px;
  }
  .half-width {
    width: calc(50% - 5px)
  }

}

