.request-time {
  display: flex;
  margin-top: 8px;
  span {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }
  &__date {
    margin-right: 30px;
  }
  &__period {
    text-transform: lowercase;
  }
}
