@import "src/styles/colors";
.dashboard-card {
  background: #FFFFFF;
  border: 1px solid #DADCE0;
  border-radius: $borderRadius2;
  padding: 20px;
  margin-bottom: 24px;
  box-sizing: border-box;

  &.none {
    padding: 0;
  }

  .card-title {
    display: block;
  }
}

.admin-dashboard-time {
  .dashboard-title {
    margin-bottom: 6px;
    display: block;
  }

  .time-wrapper {
    display: flex;
    span {
    font-weight: bold;
    }

    .delta {
      margin-left: 22px;
    }
  }
}

.summary-top-block {
  padding: 30px;
  border-bottom: 1px solid #C9C9C9;
}

.summary-info {
  display: flex;

  span {
    font-weight: bold;
  }

  .delta {
    margin-left: 16px;
    flex-wrap: wrap;
    margin-top: 5px;
    &-value {
      word-break: break-all;
    }
  }
}

.summary-block{
  height: 100%;
  display: flex;
  flex-direction: column;
  .summary-additional-info {
    height: 100%;
    display: flex;
    .additional-item {
      width: 154px;
      align-items: center;
      padding: 16px;
      box-sizing: border-box;

      &:last-child {
        border-left: 1px solid #C9C9C9;
      }
    }

    &:first-of-type{
      border-bottom: 1px solid #C9C9C9;
    }
  }
}

.dashboard-progress {
  .dashboard-progress_container {
    display: flex;
    align-items: center;
  }
}
