@font-face {
  font-family: Flags Country;
  src: url(../dist/fonts/FlagsColorWorld-8MMLM.ttf);
}

@font-face {
  font-family: 'GoogleSans Bold';
  src:  url(../dist/fonts/GoogleSans-Bold.ttf);
}

@font-face {
  font-family: 'GoogleSans Bold Italic';
  src:  url(../dist/fonts/GoogleSans-BoldItalic.ttf);
}

@font-face {
  font-family: 'GoogleSans Italic';
  src:  url(../dist/fonts/GoogleSans-Italic.ttf);
}

@font-face {
  font-family: 'GoogleSans Medium';
  src:  url(../dist/fonts/GoogleSans-Medium.ttf);
}

@font-face {
  font-family: 'GoogleSans Medium Italic';
  src:  url(../dist/fonts/GoogleSans-MediumItalic.ttf);
}

@font-face {
  font-family: 'GoogleSans Regular';
  src: url(../dist/fonts/GoogleSans-Regular.ttf);
}