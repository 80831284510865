@import "src/styles/colors";
.component-chip {
  display: inline-flex;
  border-radius: 50px !important;
  font-family: "GoogleSans Regular" !important;
  height: 28px !important;

  &_capitalize{
    text-transform: capitalize;
  }
  .MuiChip-label {
    padding: 0 16px;
  }

  &_secondary {

  }

  &_default {
    background: #DADCE0 !important;
  }

  &_pending{
    border: 1px solid $primary80 !important;
    background-color: $primary20 !important;
    color: $primary80 !important;
  }
  &_visit-completed{
    color: white !important;
    background-color:$primary50 !important;
  }

  &_primary {
    background: $primary80 !important;
    color: white !important;
  }
  &_now-later{
    border-radius: $borderRadius4 !important;
    background: $primary20 !important;
    color: $primary80 !important;
  }

  &_declined {
    color: #fff !important;
    background-color: $core40 !important;
  }
}
