.send-attach-follow-notes-content {
  padding: 30px !important;
  box-sizing: border-box;
  border-radius: 10px;

  &_header {
    padding-bottom: 20px;
    position: relative;
    display: flex;
    justify-content: space-between;

    &_wrapper-titles {
      display: flex;
      flex-direction: column;
    }

    &_close-icon {
      position: absolute;
      right: 0;
      top: 7px;

    }
  }

  &_body {
    display: flex;

    &_column {
      width: 100%;
      border-right: 1px solid #E8E8E8;

      &_container-pcp{
        max-height: 300px;
      }
      .column-title{
        display: inline-block;
      }
      .title-options{
        display: block;
      }

      .container {
        margin-top: 25px;
        width: 100%;

          .container_label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            //width: 180px;
          }
      }

      &:last-of-type {
        border-right: none;
      }

      blue{
        color: #1771B7;
      }
    }

  }

  &_footer {
    padding-top: 25px;
    display: flex;
    justify-content: flex-end;

    button:last-child {
      margin-left: 20px;
    }
  }

  .separator {
    width: 100%;
    height: 1px;
    margin: 30px 0;
    background-color: #DADCE0;
  }
}
