@import "src/styles/colors";
.container-search-input{
  position: relative;

  * {
    transition: 0.2s ease-in;
  }
  &_img{
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 12px;
    left: 14px;

    path{
      fill: $core50;
    }
  }
  &_input{
    background: $core20;
    border-radius: $borderRadius2;
    height: 44px;
    padding-left: 44px;
    border: none;
    outline: none;
    width: 100%;
    color: $core50;
    box-sizing: border-box;

    &:focus{
      background: #FFFFFF;
      box-shadow: $shadowActive;
    }

    &:focus + .container-search-input_img{
      path{
        fill: $core80;
      }
    }
  }
}
