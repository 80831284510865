.card-container_info-cloud {
  position: relative;

  .card-info__message_info-cloud {
    background: #000;
    font-size: 12px;
    line-height: 24px;
    align-items: center;
    letter-spacing: 0.15px;
    color: #FFFFFF;
    word-break: break-word;
    padding: 6px 8px;
    min-height: 36px;
    position: absolute;
    top: calc(100% + 20px);
    display: none;
    z-index: 5;
    box-sizing: border-box;
  }

  .card-info__message_info-cloud:before {
    content: ' ';
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 18px solid #000;
    position: absolute;
    top: -17px;
  }

  .info-cloud__top {
    top: calc(100% - 117px);
    min-width: 280px;
    &:before {
      content: '';
      border-top: 18px solid #000;
      border-bottom: none;
      top: 34px
    }
  }

  &:hover {
    .card-info__message_info-cloud {
      display: flex;
    }
  }
}
