.button-common-component {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 36px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  outline: none;
  border-radius: 2px !important;
  transition: .2s ease-in;
  cursor: pointer;

  &_uppercase {
    text-transform: uppercase;
  }

  &_capitalize {
    text-transform: capitalize;
  }

  &_img {
    position: absolute;
    width: 16px;
    height: 16px;

    &_right {
      right: 10px;
    }

    &_left {
      left: 10px
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }
}
