.container-label{
  display: flex;
  height: 36px;
  align-items: center;
  justify-content: space-between;

  button{
    margin-left: 15px;
  }

  &_label{
    margin: 0;
  }
}
