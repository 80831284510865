@import "src/styles/colors";
.requests-file {
  width: calc(100% + 128px);
  display: flex;
  padding: 0 20%;
  flex-direction: column;
  padding-top: 20px;
  margin-left: -64px;
  box-sizing: border-box;
  padding-bottom: 165px;
  text-transform: capitalize;
  &_footer{
    box-sizing: border-box;
    position: fixed;
    height: 132px;
    bottom: 0;
    left: 0;
    z-index: 4;
    width: calc(100% + 128px);
    min-width: calc(1200px + 128px);
    padding: 0 128px;
    padding-top: 32px;
    box-shadow: $shadowBottomBar;
    background: #FFFFFF;

    button{
      text-transform: capitalize;
      &:first-of-type{
        margin-right: 16px;
      }
    }
  }
  &_title{
    display: flex;
    justify-content: center;
    font-size: 48px;
    line-height: 56px;
    width: 100%;
    color: rgba(0, 0, 0, 0.87);
  }
  &_section{
    width: 100%;
    margin-top: 40px;
    &_header{
      display: flex;
      flex-direction: column;
      &_title{
        font-size: 22px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.87);
      }
      &_sub-title{
        font-size: 20px;
        line-height: 28px;
        color: #717579;
        margin-top: 8px;
      }
    }
    &_body{
      padding-top: 32px;
      .doctor-page-employment_container__dropzone, .doctor-page-employment_container{
        margin: 0;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    padding: 0 20%;
  }

  &__notification {
    display: block;
    text-transform: initial;
    margin-bottom: 16px;
  }

  .requests-file_section_body .patient-info-container_block-row:last-of-type {
    .custom-input-container {
      margin-bottom: 20px;
    }
  }

  .doctor-page-employment_container.dropdown-zone--error {
    .dropzone {
      border: 1px solid rgb(176, 0, 32);
    }
  }
}
