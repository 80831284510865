@import "src/styles/colors";
.group-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 20px;
  border: 1px solid #DADCE0;
  font-size: 16px;
  line-height: 24px;
  border-radius: $borderRadius2;
  box-sizing: border-box;
  cursor: pointer;

  &__title {
    margin-bottom: 12px;
  }

  &__patients-amount {
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  &__button {
    align-self: flex-end;
    width: 200px;
    height: auto !important;

    .MuiButton-label span:first-of-type {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
  }
}
