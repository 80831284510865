.covid-container-editor-content{
width: 600px !important;
.covid{
  &_txt{
    margin-top: 20px;
    display: block;
    margin-bottom: 20px;
  }
  &_positive{
margin-bottom: 16px;
  }
  &_negative{
    margin-bottom: 40px;
  }
}
}
