@import 'src/styles/colors';

.container-radio-button {
  display: block;
  position: relative;
  padding-left: 33px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $core70;

  &.active{
    color: $primary80;

    &:hover {
      .container-radio-button_checkmark {
        svg {
          box-shadow: 0 0 8px $primary60;
          border-radius: 50%;
        }
      }
    }
  }

  &:hover {
    .container-radio-button_checkmark {
      svg {
        box-shadow: 0 0 8px $primary40;
        border-radius: 50%;
      }
    }
  }

  &_disabled {
    opacity: 0.6;
  }

  &_checkmark {
    position: absolute;
    left: 0;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &_error{
    color: #B00020
  }
}
