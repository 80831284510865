.price-summary {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;

  &__row {
    display: flex;
    justify-content: space-between;
  }

  .separator{
    margin-top: 4px;
    margin-bottom: 6px;
    height: 2px;
  }

  &_charge-now{
    margin-left: auto;
  }
}
