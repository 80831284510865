@import "src/styles/colors";
.radio-block-component{
.MuiFormLabel-root{
  font-size: 16px;
  line-height: 13px;
  text-transform: capitalize;
  color: #000000 !important;
  opacity: 0.54;
}
  .MuiButtonBase-root{
    color: $primary80 !important;
  }
  .MuiFormControlLabel-label{
    font-size: 18px;
    line-height: 19px;
    color: #000000;
  }

  .MuiRadio-root{
    color: #5F6368;
    padding: 6px;
    &.Mui-checked{
      color: #1771B7;
    }

    &:hover{
    background-color: rgba(23, 113, 183, 0.04) !important;
    }
  }

  .MuiFormHelperText-root.Mui-error{
    font-size: 12px;
    letter-spacing: 0.4px;
    color: rgb(176, 0, 32);
    position: absolute;
    bottom: -13px;
  }
  &_error{
    .MuiSvgIcon-root{
      color: rgb(176, 0, 32) !important;
      path{
        color: rgb(176, 0, 32) !important;
      }
    }
  }

  .MuiFormLabel-root.Mui-error{
    color: rgb(176, 0, 32) !important;
    opacity: 1!important;
  }
}
