@import 'src/styles/variables';
@import 'src/styles/colors';

.patient-page-schedule {
  padding-top: 40px;
}

.shedule-container-event-patient {
  padding: 6px;
  height: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $primary80;
  box-shadow: 0px 1px 8px rgba(73, 159, 226, 0.5);
  color: #FFFFFF;


  &_status {
    text-align: right;
    width: 100%;
    font-size: 10px;
    line-height: 20px;
    text-transform: capitalize;
    opacity: 0.7;
  }

  &_patient {
    line-height: 20px;
    width: 100%;
    font-size: 14px;
  }

  &_patient, &_status {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_declined {
    background: #DADCE0;
    color: black;
    box-shadow: none;
    border: 1px solid #757575;

    .shedule-container-event-patient_status {
      color: rgba(255, 0, 0, 0.6);
    }
  }
}


//new
.patient-list-requests {

  &_header {
    margin-top: 30px;
    display: flex;

    .container-panel-filter {
      margin-left: 50px;
    }
  }

  &_main-title{
    margin-top: 25px;
    margin-bottom: 24px;
  }
  &_button-request {
    display: flex;
    align-items: center;
    justify-content: start;

    .drop-down-wrapper{
      margin-right: 30px;
      margin-bottom: 0;
      width: 300px;
    }

  }

  &_container-list {
    height: 60vh;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
  }

  .checkbox-value-request {
    display: flex;
    flex-direction: column;

    label {
      margin-top: 12px !important;

      &:last-of-type {
        margin-bottom: 43px;
      }
    }
  }

}

.container-events-patient {
  height: 100%;

  &_hidden {
    height: 0;
    overflow: hidden;
  }

  &_wrapper {
    padding-right: 5px;
    padding-left: 5px;
    box-sizing: border-box;
    display: flex;
    //flex-wrap: wrap;

    &_item {
      overflow: hidden;
      cursor: pointer;
      height: 525px;
      background: #FFFFFF;
      border: 1px solid #E8E8E8;
      box-sizing: border-box;
      border-radius: $borderRadius2;
      display: flex;
      margin-right: 25px;
      flex-direction: column;
      position: relative;
      padding: 40px 20px 48px 20px;

      &:hover{
        box-shadow: $shadowCard;
      }
      &_indicator-color{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 12px;

      &.pending{
        &-event{
          background: $primary10;
        }
      }
        &.assigned, &.in-progress, &.active, &.visit-completed, &.request-completed{
          &-event{
            background: $primaryOLD40;
          }
        }
        &.declined, &.draft{
          &-event{
            background: $error40;
          }
        }
      }

      .count-patients{
        margin-top: 32px;
      }
      &_header{
        margin-bottom: 34px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &_details-btn{
        bottom: 40px;
        right: 20px;
        position: absolute !important;
      }

      &_separator{
        height: 1px;
        width: 100%;
        background: #DADCE0;
        margin: 30px 0;
      }

      &_requested-by {
        margin-top: 15px;
      }

      &_title {
        display: flex;
        justify-content: space-between;

        &_status {
          font-size: 14px;
          line-height: 24px;
          background: $primary80;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 155px;
          padding: 5px 0;
          text-transform: capitalize;
          color: white;

          &.is_decline {
            background: #DADCE0;
          }
        }
      }

      &_doctor, &_patient {
        box-sizing: border-box;
        padding-right: 16px;
        width: 100%;

        &_data {
          display: flex;
          height: 80px;
          align-items: center;
          position: relative;

          &_is-mess{
            background: #F2BD42;
            margin-right: auto;
            margin-left: 16px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
          }
        }
      }

      &_doctor {
        padding-top: 16px;
        height: 120px;
        border-bottom: 1px solid #E8E8E8;
      }

      &_patient {
        position: relative;
        padding-top: 20px;
        height: calc(100% - 120px);

        &_data {
          margin-top: 20px;
          align-items: flex-start;

          .text {
            display: flex;
            flex-direction: column;

            span {
              line-height: 24px;

              &:nth-of-type(2) {
                margin-top: 3px;
              }

              &:nth-of-type(2) {
                margin-top: 2px;
              }
            }
          }
        }

        &_button {
          position: absolute;
          bottom: 16px;
          right: 16px;
        }
      }

      &:last-of-type{
        margin-right: 0;
      }
    }
  }

}


.details-request {
  overflow-x: hidden;
  display: block;
  width: calc(100% + 128px);
  transform: translateX(-64px);
  &_info{
    height: calc(100vh - 40px);
    padding-bottom: 40px;
    overflow: auto;
    &_top-block{
      width: 100%;
      height: 300px;
      //position: absolute;
      z-index: -1;
      position: relative;
      margin-bottom: -300px;
      background: linear-gradient($primary80, 300px, #fff 300px);
    }
  }

  &_buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 40px;
  }

  &_header{
    top: 0;
    height: 120px;
    z-index: 1000;
    background-color: $primary80;
    position: sticky;
    box-sizing: border-box;
    padding: 0 150px;
    align-items: center;
    width: 100%;
    display: flex;
    justify-content: center;

    button{
      position: absolute;

      &:first-child{
        left: 150px;
      }
      &:last-child{
        right: 150px;
      }
    }
    svg{
      color: white;
    }
  }

  &_body{
    > div.patient-card{
      border: 1px solid #DADCE0;
      border-radius: 10px;
      padding: 24px 20px;
      background: white;
    }

    &_title-patient{
      padding: 0 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &_content{
        display: flex;
        align-items: center;

        >span{
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.15px;
          color: #757575;
          margin-right: 10px;
        }
        svg{
          fill: #BAC3C9;
          cursor: pointer;
        }
      }
    }

    .personal-patient-card{
      margin-bottom: 20px;
    }
    &_label{
      display: block;
      margin-bottom: 12px;
      text-transform: capitalize;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.15px;
      padding: 0 20px;
      color: #5E6878;
    }
    &_relation_tag
    {
      display: block;
      margin-bottom: 12px;
      margin-top: 16px;
      text-transform: capitalize;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.15px;
      padding: 0 20px;
      color: #717579;
    }
    white{
      color: white;
    }
    &_you{
      text-transform: capitalize;
      display: block;
      margin: 21px 0 12px 0;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.15px;
      color: #5E6878;
    }

    .count-patients{
      margin-top: 21px;
    }
  }
  &_body,
  //&_header,
  &_eta, &_buttons {
    width: 60%;
    margin: 22px auto;
  }
  &_buttons{
    margin: 40px auto;
  }

  &_body {
    &_container-address {
      margin-top: 23px;

      &_map {
        margin: 30px 0;
        box-sizing: border-box;
        border-radius: 6px;
        width: 100% !important;
      }
    }

    &_files{
      margin-top: 20px;
      border-radius: 6px;
      background: #FFFFFF;
      padding: 20px;
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);

      &_only-active{
        box-shadow: none;
        padding: 0;
        margin-top: 30px;

        .details-request_body_files_item{
          margin-top: 16px;
        }
      }
      &_header{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .icon-component{
          transform: rotate(90deg);
        }

        &_title{
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: #777777;
        }

        &_icon{
          transform: rotate(-90deg) !important;
        }
      }

      &_item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        &:first-of-type{
          margin-top: 0;
        }

        .file-attachment{
          text-decoration: none;
        }

        &_download{
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: $primary80;
          cursor: pointer;
          text-decoration: none;
        }
      }
    }

    &_address {
      margin-top: 17px;
      display: flex;

      &_img {
        margin-right: 16px;
      }

      &_wrapper-address {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .component-card-request-details_price-block {
    width: calc(100% + -4px);
  }

  &_close-icon {
    position: absolute;
    right: 130px;
    top: 70px;

    path {
      fill: #fff;
    }
  }

  .approximate-eta {
    background: white;
    border: 1px solid $core30;
    box-sizing: border-box;
    border-radius: $borderRadius2;
    padding: 8px 24px;
    margin: 30px 0 0 0;
    color: $primary80;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 60%;
    }

    &_container-extra {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .typography {
        margin-left: 10px;
      }
    }

    &_time {
      font-size: 34px;
      line-height: 38px;
      font-weight: 600;

      &-last {
        margin-left: 10px;
      }
    }

    &_left {
      color: $primary80;
      margin-left: 9px;
    }
  }
}

.component-card-request-details {
  box-sizing: border-box;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: $borderRadius2 $borderRadius2 $borderRadius8 $borderRadius8;
  padding: 20px 16px 0 16px;

  &_header{
    align-items: center;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
  }

  .separator{
    margin-bottom: 30px;
    margin-top: 30px;
    height: 1px;
    width: 100%;
    background-color: #DADCE0;
  }

  &_services {
    margin-top: 28px;

    &_button{
      display: flex;
      justify-content: flex-end;
      margin-top: 24px;
    }

    &_price-services{
      display: flex;
      justify-content: flex-end;
      margin-top: 12px;

      span{
        &:last-of-type{
          margin-left: 8px;
        }
      }
    }
    &_separator{
      height: 1px;
      width: 100%;
      margin: 30px 0;
      background: #DADCE0;
    }

    &_item {
      margin-top: 12px;
      display: flex;

      .name {
        width: 100%;
      }

      .container-info {
        width: 260px;
        display: flex;
        justify-content: space-between;

        &_cost {
          margin-right: 20px;
        }

        &_status {

        }
      }
    }
  }


  &_doctor {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &_chat{
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
    }

    .separator {
      width: calc(100% + 16px);
      height: 1px;
      background-color: #DADCE0;
      margin: 30px 0
    }

    &_img {
      display: flex;
      align-items: center;
    }

    &_text {
      margin-top: 16px;
      display: flex;
      flex-direction: column;

      span {
        margin-top: 8px;
      }
    }

    img {
      margin-right: 30px;
    }
  }

  &_title {
    display: flex;
    justify-content: space-between;

    &_patient {
      height: 28px;
      padding: 0 15px;
      display: flex;
      align-items: center;
      background: #F3F8FB;
      border-radius: 8px;
    }
  }

  &_user-data {
    margin-top: 12px;
    display: flex;

    &_img {
      height: 64px;
      width: 64px;
      border-radius: 50%;
    }

    &_data {
      display: flex;
      flex-direction: column;
      margin-left: 18px;

      &_initials {
      }

      &_date {
      }

      &_phone {
      }
    }
  }

  &_request-type {
    margin-top: 30px;
  }

  &_symptoms {
    display: flex;
    flex-wrap: wrap;
    margin-top: 22px;

    &_item {
      padding: 0 15px;
      display: flex;
      align-items: center;
      min-height: 32px;
      background: rgba(23, 113, 183, 0.1);
      border-radius: 16px;
      line-height: 28px;
      margin-top: 8px;
      margin-right: 8px;
    }
  }

  &_comment-block {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 16px;

    &_title {

      line-height: 24px;
    }

    &_text {
      font-style: italic;
      line-height: 20px;
      margin-top: 12px;

    }
  }

  &_author-block {
    display: flex;
    flex-direction: column;
    padding-top: 4px;

    &_title {
      line-height: 24px;
    }

    &_container {
      display: flex;
      margin-top: 12px;

      &_author {
        line-height: 24px;
      }

      &_relationship {
        margin-left: 10px;
        line-height: 24px;
      }
    }

    &_phone {
      line-height: 24px;
      margin-top: 4px;
    }
  }

  &_price-block {
    margin-top: 40px;
    padding-right: 16px;
    padding-left: 20px;
    width: calc(100% + 32px);
    transform: translateX(-16px);
    height: 72px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(243, 243, 243, 0.6);
    border-top: 1px dashed #B8B8B8;

    &_what-is {
      text-decoration: underline;
    }

    &_container-price {
      display: flex;
      justify-content: flex-end;
    }

    &_label {

    }

    &_summary {
      font-weight: 500;
    }

    &_right, &_left {
      position: absolute;
      width: 15px;
      height: 30px;
      top: -15px;
      box-shadow: 0px 1px 4px inset rgba(0, 0, 0, 0.2);
      background: white;
      display: inline-block;

      &:after {
        content: '';
        position: absolute;
        width: 8px;
        height: 28px;
        background-color: white;
        top: 1px;
        left: -4px;
        border-radius: 0 4px 4px 0;
      }
    }

    &_right {
      border-bottom-left-radius: 30px;
      border-top-left-radius: 30px;
      right: 0;

      &:after {
        left: 11px;
        border-radius: 4px 0 0 4px;
      }
    }

    &_left {
      border-left: 0;
      border-bottom-right-radius: 15px;
      border-top-right-radius: 15px;
      left: 0;
    }
  }
}

.details-request-services, .details-request-cards{
  height: calc(100vh - 70px);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;

  &_header{

    display: flex;
    justify-content: center;
    width: 765px;
    position: relative;
    &_arrow{
      top: 10px;
      position: absolute;
      left: 0;
    }
  }

  *{
    box-sizing: border-box;
  }

  &_list{
    //height: calc(100% - 80px);
    margin-top: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 765px;
    border: 1px solid #DADCE0;
    border-radius: $borderRadius2;
    padding: 30px 145px 65px 145px;

    .personal-info-patient_add-card_info{
      border: none;
      margin-top: 0;
    }
    .emr-page-container_checkbox_item{
      padding-left: 18px;
    }

    &_options{
      width: 100%;
      padding: 0 5px;
      overflow: auto;
      height: calc(100% - 120px);
      max-height: calc(100% - 120px);
      margin-top: 30px;

      &_option-card{
        margin-top: 8px;
        box-sizing: border-box;
        border: 1px solid #E3E3E3;
        align-items: center;
        border-radius: 3.5px;
        padding: 0 18px 0 13px;
        height: 54px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        &_radion{
          cursor: pointer;
        }

        &:first-of-type{
          margin-top: 0;
        }

        div{
          align-items: center;
          display: flex;
        }

        &_info{
          display: flex;
          align-items: flex-start !important;
          flex-direction: column;
        }
        img{
          margin-right: 17px;
        }
      }
      &_empty{
        margin-top: 64px;
        display: flex;
        flex-direction: column;
        align-items: center;

        img{
          margin-bottom: 30px;
        }
      }

      &_option{
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 16px 23px;
        justify-content: space-between;
        border: 1px solid #DADCE0;
        border-radius: 10px;
        margin-top: 12px;
        & > .typography{
          word-break: break-all;
          width: 75%;
        }

        &:first-of-type{
          margin-top: 0;
        }

        .emr-page-container_checkbox_item{
          margin: 0;
        }
      }
    }
    &_buttons{
      padding: 0 5px;
      margin-top: 30px;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      button{
        &:last-of-type{
          margin-left: 20px;
        }
      }
    }
  }
}

.details-request-cards{
  min-height: 500px;
  .personal-info-patient_add-card{
    width: 765px;
  }
  &_list {
    &_options {
      height: calc(100% - 95px);
      max-height: calc(100% - 95px);
    }
  }
}

.confirmation-decline-all-content{

  &_options-confirmation{
    display: flex;
    align-items: center;

    &_point{
      min-width: 6px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $primary80;
      margin-right: 8px;
    }
  }

  .popup-component-common-content_body{
margin-top: 16px;
    margin-bottom: 24px;
  }
}

@media(max-width: 1200px) {
  .patient-list-requests{
    .details-request{
      //&_header{
      //  width: 60%;
      //}
      &_eta{
        width: 60%;
      }
      &_body{
        width: 60%;
      }
      &_buttons{
        width: 60%;
      }
    }
    .details-request_info{
    }
  }
}

@media(max-width: 900px) {

  .details-request{
    width: calc(100% + 32px);
    transform: translateX(-16px);

    &_header {
      padding: 0 50px;

      button{
        position: absolute;

        &:first-child{
          left: 50px;
        }
        &:last-child{
          right: 50px;
        }
      }
      > span {
        font-size: 28px;
      }
    }

    &-services, &-cards{
      &_header{
        width: calc(100% - 32px) !important;
      }
      &_list{
        width: calc(100% - 32px);

      }
    }
    &-services{
      &_header{
        width: 100%;
      }
    }
    //&_header,
    &_eta, &_body, &_buttons{
      width: 100%;
    }
    &_info{
      //padding: 16px;
    }

    &_close-icon{
      top: 50px;
      right: 20px;
    }

    .component-card-request-details_services_item{
      .name{
        word-break: break-all;
        padding-right: 15px;
      }
    }
  }

  .details-request-cards{
    .details-request-services_header{
      width: 100%;
    }
  }
  .patient-list-requests {
    &_main-title{
      span{
        display: inline-block;
        text-align: center;
      }
    }


    .details-request{
      width: calc(100% + 32px);
      transform: translateX(-16px);

      //&_header {
      //  > span {
      //    font-size: 28px;
      //  }
      //}

      &-services, &-cards{
        &_header{
          width: calc(100% - 32px);
        }
        &_list{
          width: calc(100% - 32px);

        }
      }

      .details-request-cards{
        margin-left: 16px;
        width: calc(100% - 32px);
      }
      &-services{
        &_header{
          width: 100%;
        }
      }
      //&_header,
      &_eta, &_body, &_buttons{
        width: calc(100% - 32px);
      }
      &_info{
        //padding: 16px;
      }

      &_close-icon{
        top: 50px;
        right: 20px;
      }

      .component-card-request-details_services_item{
        .name{
          word-break: break-all;
          padding-right: 15px;
        }
      }
    }

    &_header {
      justify-content: center;

      > span {
        margin-bottom: 10px;
      }
    }

    .component-filter-value{
      > span{
        font-size: 15px;
      };
    }

    &_container-list {
      .container-events-patient {
        &_title {
          display: flex;
          justify-content: center;
        }

        &_wrapper {
          //flex-wrap: nowrap;
          //flex-direction: column;
          //align-items: center;
          //&_item{
          //  margin-right: 0;
          //}
        }
      }
    }

    &_button-request {
      margin-top: 40px;
      margin-bottom: 20px;
      justify-content: center;
    }
  }
}
@media(max-width: 768px) {
  .details-request-cards{
    .details-request-services_header{

      > span{
        font-size: 20px;
      }
    }
  }
  .patient-list-requests {

    &_main-title {
      display: flex;
      justify-content: center;
      span {
        font-size: 28px;
      }
    }

    .details-request {
      //transition: height 1ms 1s;
      width: calc(100% + 32px);
      transform: translateX(-16px);

      &-services, &-cards {
        &_header {
          &_arrow{
            top: 3px;
          }
          .typography{
            font-size: 24px;
          }
        }

        &_list {
          > span{
            text-align: center;
            font-size: 18px;
          }
          padding: 30px;
        }
      }
    }
    &_header {
      flex-direction: column;
      align-items: center;
      .container-panel-filter{
        width: 457px;
        margin-top: 16px;
        margin-left: 0;

        &_left-part{
          width: 100%;
          > button{
            width: 100%;
          }
        }
        &_trigger-button-isValue{
          width: 100%;
        }
        .component-filter-value{
          justify-content: space-between;
          margin-right: 0;
        }

        .popup-filters-container-content{
          width: 200px !important;
        }
      }
      > span{
        font-size: 28px;
      }
    }

    &_container-list {
      .container-events-patient {
        &_title {
          > span{
            font-size: 22px;
          }
        }
      }
    }

    &_button-request {
      justify-content: center;

      .drop-down-wrapper{
        width: 457px;
      }
    }
  }

  .details-request_chat-open{
    height: 0 !important;
  }
}


@media(max-width: 576px) {
  .details-request-cards{
    .details-request-services_header{
      > span{
        font-size: 16px;
      }
    }
  }
  .patient-list-requests {
    &_main-title {
      display: flex;
      justify-content: flex-start;
      span {
        text-align: left;
      }
    }

    &_header{
      > span.typography{
        width: 100%;

      }
    }

    .details-request {
      &_header {
        padding: 0 16px;

        button{
          position: absolute;

          &:first-child{
            left: 16px;
          }
          &:last-child{
            right: 16px;
          }
        }
        > span {
          font-size: 18px;
        }
      }
      &_close-icon{
        top: 46px;
      }
      &-services, &-cards {
        &_header {
          justify-content: center;
          .typography{
            text-align: right;
            margin-left: 30px;
            font-size: 16px;
          }
        }

        &_list {
          > span{
            text-align: center;
            font-size: 16px;
          }

          &_options{
            margin-top: 16px;
          }

          &_buttons{
            flex-direction: column;
            button{
              width: 100%;
              &:last-of-type{
                margin-left: 0;
                margin-top: 16px;
              }
            }
          }
        }
      }

      &_eta {
        .approximate-eta {
          flex-direction: column;
          align-items: flex-start;

          .approximate-eta_container {
            white-space: nowrap;
            justify-content: flex-start;
          }
        }
      }

      &_body {
        .component-card-request-details_user-data_img {
          display: none;
        }
        .component-card-request-details_user-data_data{
          margin-left: 0;
        }
      }

      .component-card-request-details_services_button{
        button{
          width: 100% !important;
        }
      }
    }

    &_header{
      .container-panel-filter{
        width: 100%;
        margin-top: 16px;
        margin-left: 0;
      }
    }

    &_button-request {
      align-items: center;
      flex-direction: column;
      .drop-down-wrapper{
        width: 100%;
      }
    }
    &_container-list {
      .container-events-patient {

        &_title{
          justify-content: flex-start;
        }
        &_wrapper {
          &_item{
            //height: 425px;
            width: 100%;
            img{
              display: none;
            }
            .count-patients_body_patients{
              img{
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
