.patient-form{
  width: 70%;
  overflow-y: auto;
  padding-top: 4px;

  &_subtitle{
    display: block;
    margin: 40px 0;
  }

  &_row{
    display: flex;
    grid-gap: 8px;
  }

  &_gender{
    margin-bottom: 24px;
    &_options{
      margin-top: 12px;
      display: flex;
      grid-gap: 24px;
    }
  }
}