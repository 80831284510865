
  .follow-up-content {
    padding: 200px 20% 10% 20% !important;

    &_header{
      padding-bottom: 50px;
      position: relative;
      display: flex;
      justify-content: center;

      &_wrapper-titles{
        display: flex;
        flex-direction: column;
      }
      &_close-icon{
        position: absolute;
        right: 0;
        top: 7px;

      }

    }
    &_body{
      height: calc(100% - 139px);
      .custom-input-container{
        width: 40%;
        margin-bottom: 13px;
      }
      .textarea-common-component{
        height: 100%;
        &_textarea{
          margin-bottom: 0;
          padding: 20px;
          margin-top: 12px;
          height: calc(100% - 81px);

          font-size: 16px;
          font-family: "GoogleSans Regular", sans-serif;
        }
        &_placeholder{
          left: 20px;
        }
      }

    }
    &_footer{
      padding-top: 25px;
      display: flex;
      justify-content: flex-end;

      button:last-child{
        margin-left: 20px;
      }
    }
  }


