

.header-img {
  width: 100%;
}
.header-title {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 18px;
}

.header__doctor-info {
  font-size: 16px;
  text-align: left;
  margin-top: 20px;
  .doctor-info {
    font-weight: bold;
    p{
      margin: 0;
    }
  }
  .doctor-email {
    color: black;
  }
}
