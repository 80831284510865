.container-emr-request{
  &_info{
    display: flex;
    width: calc(100% + 128px);
    margin-left: -64px;
    height: calc(100vh - 64px);
  }

  *{
    box-sizing: border-box;
  }
}
