@import "src/styles/colors";

.single-date-picker{
  height: 56px;
  width: 227px;
  box-sizing: border-box;
  border: 1px solid $core50;
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 2px;
  padding: 0 15px;

  &_disabled {
    opacity: 0.5;
  }

  &_label{
    padding: 0 7px;
    position: absolute;
    top: -8px;
    background-color: white;
    left: 8px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.4px;
    color: $core50;
  }
  &_error-container{
    border-color: #B00020;

    .single-date-picker_label{
      color: #B00020;
    }
  }
}
