.visit-details {
  display: flex;
  width: 100%;
  flex-direction: column;

  &__datepicker {
    width: 100%;

    &-block {
      width: 100%;
    }
  }

  &__timeslots {
    display: flex;
    width: 100%;

    .custom-input-container {
      width: 50%;
      margin: 0;
    }
  }

  &__date {
    position: relative;
    width: 100%;
  }

  &__calendar-icon {
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    position: absolute;
  }

  &__row {
    display: flex;

    &_date-picker, .time-slot__container{
      width: calc((100% - 10px) / 2);

      .single-date-picker{
        width: 100%;
      }
    }

    .custom-input-container{
      margin: 0;
    }

    .time-slot__error-container{
      position: absolute;
      margin: 0;
    }
    &:not(:last-of-type) {
      margin-bottom: 34px;
    }

    & > div:not(:last-of-type) {
      margin-right: 10px;
    }

    .card-container_info-cloud {
      width: 100%;
    }

    .drop-down-wrapper {
      margin-bottom: 0;
    }

    .react-datepicker-popper {
      top: -409px !important;
      left: 0 !important;
    }

    .single-date-picker_error {
      position: absolute;
      margin-top: 3px;
    }
  }

  .time-slot__container{
    .drop-down__option{
      text-transform: lowercase;
    }
  }
}
