@import "src/styles/colors";
.doctor-page-employment {
  display: flex;
  margin-top: 8px;
  text-decoration: none;
  color: $primary80;
  &-title {
    margin-left: 5px;
  }
  &_container {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;

    //.single-date-picker,{
    //  margin-bottom: 25px;
    //}
    //.custom-input-container{
    //  margin-bottom: 0 !important;
    //}
    //.drop-down-wrapper{
    //  margin-top: 25px;
    //}
  }
}

.doctor-page-overflow {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
  .employment__archive {
  display: flex;
  justify-content: space-between;
}

.doctor-page-employment_container__dropzone {
  cursor: pointer;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;


  & .dropzone {
    position: relative;
    z-index: 2;
    background: #FFF;
    border: 1px dashed #DBDCE0;
    box-sizing: border-box;
    border-radius: $borderRadius2;
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 111px;
    justify-content: center;
    & img {
      width: 50px;
      align-self: center;
    }
  }
  &-hover {
    z-index: 3;
    display: flex;
    justify-content: center;
    background: $primary10;
    border: 1px solid $primary80;
    box-sizing: border-box;
    border-radius: $borderRadius2;
    font-size: 28px;
    line-height: 56px;
    align-items: center;
    text-transform: capitalize;
    color: $primary70;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 111px;
    cursor: pointer;
    opacity: 0.95;
  }
}

.dropzone-btn {
  border: none;
  background: transparent;
  color: $primary70;
  padding-top: 13px;
}

.dropzone {
  &_title {
    display: inline;
  }
}
.opacity-complete {
  opacity: 0.7;
}
.document {
  display: flex;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  &-common {
    display: flex;
    justify-content: space-between;
  }
  &_info {
    display: flex;
    width: 50%;
    & .folder {
      position: relative;
      &-view {
        align-items: center;
        height: 16px;
        padding: 0 5px;
        display: flex;
        justify-content: center;
        background: $primary80;
        color: white;
        letter-spacing: 0.15px;
        border-radius: 3px;
        text-transform: uppercase;
        font-size: 10px;
        text-align: center;
        position: absolute;
        top: -29px;
        left: -4px;
      }
    }

    &-name {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
    }
    &-optional {
      color: rgba(0,0,0,0.87);
      font-size: 14px;
      width: 80%;
      align-items: center;
      margin-left: 16px;
      display: flex;
      white-space: nowrap
    }
  }

  .document-optional {
    display: flex;
    flex-direction: column;
    padding-left: 50px;
    > span{
      margin-bottom: 8px;
      display: block;
    }


    &_comment {
      font-size: 12px;
      line-height: 20px;
      display: inline-block;
      padding: 12px 15px;
      background: #F5F6F7;
      border-radius: 12px;
      color: #717579;
    }
    & .decline-status {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      color: #FF0000;
      opacity: 0.6;
    }
    & .decline-reload {
      display: flex;
      justify-content: space-between;
      &__info {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.4px;
        color: #1771B7;
        opacity: 0.6;
      }
      &__btn {
        border: none;
        background: transparent;
        cursor: pointer;
      }
      &__container {
        display: flex;
        justify-content: space-between;
        & span {
          color: #1771B7;
          opacity: 0.6;
        }
      }
    }
  }
}

.document-zone-date-picker{
  margin-bottom: 25px;
  position: relative;
  .single-date-picker_error{
    position: absolute;
  }
}



