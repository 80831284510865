@import "src/styles/colors";

.textarea-common-component{
  position: relative;
  font-size: 16px;

  &_textarea{
    font-size: 16px;
    position: relative;
    border: 1px solid $core50;
    resize: none;
    min-height: 100px;
    width: 100%;
    border-radius: 2px;
    padding: 15px;
    outline: none;
    z-index: 2;
    background: none;
    box-sizing: border-box;
    margin-bottom: 20px;
    font-family: "GoogleSans Regular";

    &[disabled]{
      cursor: not-allowed;
      opacity: 0.7;
    }

    &:focus + span{
      z-index: 3;
      padding: 0 3px;
      color: $primary80;
      font-size: 12px;
      background-color: white;
      top: 4px;
    }

    &:focus{
      border-color: $primary80;
    }
  }

  &_placeholder{
    z-index: 1;
    top: 24px;
    color: $core50;
    transition: .5s;
    position: absolute;
    left: 15px;
    &_disabled{
      color: rgba($core50, 0.7);
      //opacity: 0.7;
    }

    &_isValue{
      z-index: 3;
      padding: 0 3px;
      font-size: 12px;
      background-color: white;
      top: 4px;
    }
  }

  &_error{
    .textarea-common-component_textarea{
      border-color: #B00020;
    }
    .textarea-common-component_placeholder{
      color: #B00020 !important;
    }
  }
}
