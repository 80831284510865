.user-data {
  display: flex;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  flex-direction: column;

  img {
    width: 64px;
    height: 64px;
    margin-right: 16px;
  }

  &__type {
    margin-bottom: 16px;

    .MuiSvgIcon-root {
      font-size: 18px;
      fill: #219653;
      margin-right: 8px;
      vertical-align: middle;
    }
  }

  &__info {
    width: 100%;
    display: flex;
  }

  &__text {
    overflow: hidden;
    display: flex;
    flex-direction: column;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 24px;
    }
    &_email{
      margin-top: 4px;
    }
    &_phone{
      margin-top: 4px;
    }
  }

  &__request-indicator .MuiChip-label {
    border-radius: 8px !important;
    font-size: 16px;
    line-height: 24px;
  }
}
