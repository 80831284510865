@import "src/styles/colors";

.btn-component {
  border-radius: 2px !important;

  font-family: "GoogleSans Regular" !important;
  .MuiButton-label{
    white-space: nowrap;
    text-transform: none;
  }

  &_uppercase{
    .MuiButton-label{
      text-transform: uppercase;
    }
  }
  &_capitalize{
    .MuiButton-label{
      text-transform: capitalize;
    }
  }
  &.MuiButtonBase-root {
    min-width: auto;
    height: 36px;
    font-size: 16px;
    line-height: 24px;

    &.MuiButton-textPrimary {
      color: $primary80 !important;

      &:hover {
        background: $primary20 !important;
      }
    }


    &.MuiButton-contained {
      background: $primary10 !important;
      color: $primary80 !important;
      box-shadow: none;
      border: 1px solid $primary80 !important;

      &:hover {
        color: $primary70 !important;
        border: 1px solid $primary70 !important;
        background-color: $primary20 !important;
      }

      &.Mui-disabled{
        color: $core40 !important;
        background-color: $core20 !important;
        border-color: $core40 !important;
      }

      &.MuiButton-containedPrimary {
        background: $primary80 !important;
        color: white !important;

        &:hover{
          background-color: $primary70 !important;
          border-color: $primary70 !important;
        }
        //&:focus{
        //  background-color: $primary100 !important;
        //  border-color: $primary100 !important;
        //}

        &.Mui-disabled{
          background-color: $core30 !important;
          border-color: $core30 !important;
        }
      }
    }
  }

  .MuiButton-startIcon {
    margin: 0;
  }
}



