.excuse-container-editor-content{
width: 600px !important;

  &_container{
    margin: 10px 0;

    &_date-picker{
      display: inline-block;
    }
    .custom-input-container{
      width: 150px;
      height: 36px;
      margin-bottom: 0;
      display: inline-block;
      margin-right: 4px;
      &_placeholder{
        top: 8px;

        &_isValue{
          top: -7px
        }
      }
      .custom-input-container_error {
        position: absolute;
        display: flex;
        line-height: initial;
      }
    }
    .single-date-picker{

      width: 150px;
      height: 36px;
    }
  }
  .typography{
    line-height: 36px;
    margin-right: 4px;
  }
}
