@import "src/styles/colors";
.drop-down-wrapper {
  width: 100%;
  position: relative;
  margin-bottom: 25px;
  position: relative;

  &_error-text{
    position: absolute;
    top: 60px;
  }

  &_disabled{
    opacity: 0.7;
  }


  &_error{
.drop-down__control{
  border-color: #B00020 !important;
}
  }

  &_label{
    z-index: 1;
    top: 18px;
    color: rgba(0, 0, 0, 0.6);
    transition: .5s;
    position: absolute;
    left: 16px;

    &_isValue, &_isOpen{
      left: 9px;
      padding: 0 7px;
      font-size: 12px;
      background-color: white;
      top: -7px;
    }
    &_isValue{
      transition: 0s;
      z-index: 3;
    }
    &_isOpen{
      z-index: 6;
    }
    &_error{
      color: #B00020 !important;
    }
  }

  .drop-down-container {
    z-index: 2;
    outline: none;

    &.drop-down-container_isOpen{
      z-index: 5;
    }

    .drop-down__single-value {
      font-size: 16px;
      color: $core100;
    }

    .drop-down__multi-value {
      display: flex;
      color: $core100;
      align-items: center;
      background: $primary20;
      border-radius: 100px;
      height: 32px;
      margin: 3px 13px 3px 0;

      .drop-down__multi-value__remove {
        color: $core100;
        border-radius: 50%;
        background-color:$core30;
        width: 17px;
        height: 17px;
        margin-left: 13px;
        margin-right: 9px;
        transition: color .3s, background-color .3s;

        > svg{
          width: 100%;
          height: 100%;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .drop-down__multi-value__label {
        color: $core100;
        font-size: 16px;
        padding: 3px 3px 3px 10px;
      }
    }

    .drop-down__option {
      background-color: transparent;
      padding: 14px 16px;
      width: auto;

      &:hover {
        background: $primary20;
      }

      &--is-selected {
        color: $core100;
        background: $primary20;
      }
    }

    .drop-down__placeholder {
      color: #707E8B;
      font-size: 16px;
    }

    .drop-down__menu {
      margin-top: 1px;
      z-index: 3;
      box-shadow: $shadowDropdown;

      &-list--is-multi {
        //.drop-down__option--is-selected {
        //  background: transparent;
        //}
      }
    }

    .drop-down__control {
      padding-right: 30px;
      background: none;
      border: 1px solid $core50;
      //background: #F4F4F4;
      border-radius: 2px;
      outline: none !important;
      box-shadow: none;
      min-height: 56px;
      position: relative;
      max-height: 250px;
      overflow-y: auto;


      .drop-down__value-container {
        padding: 3px 30px 4px 13px;
      }

      .indicator {
        position: absolute;
        width: 0;
        height: 0;
        margin-left: 2px;
        vertical-align: middle;
        border-top: 5px solid;
        border-right: 4px solid transparent;
        border-left: 4px solid transparent;
        content: '';
        right: 16px;
        top: 26px;
        color: $core70;
      }
      &--menu-is-open {
        //background: #FFF;
        //border-color: white;
        //box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

        .indicator {
          border-top: none;
          border-bottom: 5px solid;
        }
      }

    }
  }

  .error {
    .drop-down__control {
      &:after {
        content: '';
        bottom: 0;
        position: absolute;
        width: calc(100% - 8px);
        left: 4px;
        height: 2px;
        border-radius: 2px 2px 0 0;
        background-color: #FF3C3C;
      }
    }
  }
}
