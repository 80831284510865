@import "src/styles/colors";
.emr-delete-box{
  position: relative;
  padding-right: 64px;
  &_delete{
    height: 100%;
    bottom: 0;
    right: 0 !important;
    width: 40px;
    transition-property: right;
    transition: 0.3s ease-out !important;
    display: flex;
    justify-content: flex-end !important;
    border-radius: 4px !important;
    position: absolute !important;
    &:hover {
      height: 100%;
      border: none !important;
      background: rgba($core50, 0.6) !important;
      backdrop-filter: blur(2px) !important;
      width: 100%;
      top: 0;
    }

  }
}
