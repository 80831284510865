@import "fonts";
@import "src/styles/colors";

html, body, #root {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: "GoogleSans Regular";

  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-width: 0;
    border-radius: 6px;
    background-color: #e1e1e1;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #c4c4c4;
  }

  ::-webkit-scrollbar-track {
    border-width: 0;
  }

  .popup-overlay {
    overflow: auto;
  }
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: calc(100vh - 16px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Toastify__toast-container{
  padding: 0;
  margin: 0;
  width: 420px;
  top: 5em;


  .Toastify__toast.custom-toast-notification-pusher{
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 2px 24px rgba(30, 66, 123, 0.14);
    border-radius: $borderRadius4;

    .Toastify__close-button{
      display: none;
    }
  }

  .Toastify__toast.custom-toast-notification{
    padding: 16px;
    background: #FFFFFF;
    box-shadow: 0px 2px 24px rgba(30, 66, 123, 0.14);
    border-radius: $borderRadius4;

    .Toastify__close-button{
      display: none;
    }
  }
}
.custom-toast-error {
  .custom-toast.Toastify__toast {
    background: $primary80;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.32);
    border-radius: 6px;
    font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Helvetica, Arial, sans-serif;
    padding: 20px;

    &:before {
      background: url("../dist/images/common/toast.svg") no-repeat;
      content: "";
      width: 25px;
      height: 25px;
      background-size: contain;
      align-self: center;
    }

    .Toastify__toast-body {
      font-size: 15px;
      color: #ffffff;
      margin-left: 10px;
    }

    .Toastify__close-button {
      align-self: center;
      opacity: 1;
      text-indent: -9999px;
      align-content: center;
      background: url("../dist/icons/cross.svg") no-repeat;
      width: 14px;
      height: 14px;
      background-size: contain;
    }
  }
}
.Toastify__toast--error{
  background: #DE2121 !important;

  &:before {
    content: "";
    width: 27px !important;
    height: 27px !important;
    background-size: contain;
    align-self: center;
    background: url("../dist/images/common/toast-error.svg") no-repeat !important;
  }


  .Toastify__close-button{
    background: url("../dist/icons/cross-white.svg") no-repeat !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*{
  outline: none !important;
}


.no-default-style-button{
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
}

@media (max-width: 576px) {
  .Toastify__toast-container{
    right: 20px;
    left: initial;
    width: calc(100% - 40px);
  }
  .Toastify__toast{
    margin-bottom: 1em;
  }
}


//.overflow-hidden{
//  overflow: hidden !important;
//}
