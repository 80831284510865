@import "src/styles/colors";
.emr-notes-input{
    position: relative;
    display: inline-block;
    min-width: 100px;
    min-height: 42px;
    box-sizing: border-box;
    border: 1px solid $primary80;
    padding: 10px 5px;
    border-radius: $borderRadius2;
    margin: 4px 5px 0 5px;

    &_container{
        display: inline-block;
        width: 100%;
        white-space: nowrap;

        &_text{
            white-space: pre-wrap;
            width: 100%;
            display: inline-block;
            height: 100%;
        }
    }

    &_error{
        border-color: #B00020;
    }

    &_value{
        letter-spacing: 0.5px;
font-style: italic;
        color: #50555C;
    }
    &_textarea{
        min-height: 90px;
        display: block;
    }
}
