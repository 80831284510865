@import "src/styles/colors";
.past-medical-history {
  display: flex;
  flex-direction: column;
  width: 45%;
  overflow: auto;
  padding-left: 30px;
  padding-right: 30px;


  .textarea-common-component_textarea{
    margin-top: 12px;
  }

  .textarea-common-component{
    margin-top: 4px;
  }
  &_header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  &_li-delete, &_li-download, &_li-edit{
    display: flex;
    align-items: center;
    .icon-component{
      display: flex !important;
    }

    span{
      position: static !important;
    }
  }
  &_li-delete{
    .icon-component{
      margin-left: 12px !important;
      margin-right: 23px !important;
    }
  }
  &_li-edit{
    .icon-component{
      margin-left: 9px !important;
      margin-right: 21px !important;
    }

  }

  &_history {
    margin-top: 25px;
    display: flex;
    flex-direction: column;

    &_items {
      margin-top: 4px;
      display: flex;
      flex-wrap: wrap;

      span {
        margin: 4px 4px 0 0;

        &.pmh-item-info{
          border-radius: $borderRadius4 !important;
        }
      }
    }

    &_list {
      margin-top: 4px;
      display: flex;
      flex-direction: column;

      &_item {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .container__attachment-pmh {
    margin-top: 20px;
  }
}

.attachment-input-file {
  width: 0;
  height: 0;
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.pmh-container-content-content {

  .container-pmh-edit {
    padding-right: 10px;
    padding-top: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 300px;
    max-height: 480px;
    overflow-y: auto;

    .textarea-common-component_textarea{
      margin-top: 12px;
      margin-bottom: 0;
    }

    .textarea-common-component{
      margin-top: 13px;
    }

    &_left-column, &_right-column {
      width:100%;
    }

    .veradigm-button-container{
      margin-top: 10px;
    }
    &_left-column, &_right-column {

      .pmh-row__elements {
        display: flex;
        justify-content: space-between;
        margin-bottom: 25px;
        align-items: center;

        .drop-down-wrapper {
          margin-bottom: 0;
        }

        &-trash {
          margin-right: 15px;
          &_left {
            margin-left: 15px;
          }
        }
      }

      &_block {
        margin-bottom: 29px;

        .medicastion-view_item{
          width: 100%;
          padding: 4px 12px;
          transform: none;
        }
        &_add-button {
          display: flex;
          align-items: center;
          margin-top: 10px;

          .icon-component {
            width: 14px;
            height: 14px;

            svg {
              width: 14px;
              height: 14px;
            }
          }
        }
        &_veradigm-values{
          display: flex;
          flex-direction: column;

        }
      }
    }
  }
}
