@import "src/styles/colors";
.emr-notes-radio{
  position: relative;
  border: 1px solid $primary80;
  border-radius: $borderRadius2;
  display: inline-block;
  height: 42px;
  padding: 10px 5px;
  box-sizing: border-box;
  margin: 4px 5px 0 5px;

  &_error{
    border-color: #B00020 !important;
  }
}
