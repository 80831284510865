.container-input-flag{
display: flex;
  height: 36px;


  &_select-flag {
    .select__menu-list {
      overflow-x: hidden;
      .select__option {
        padding: 9px 9px !important;
        margin: 0 4px !important;
        white-space: nowrap;
        font-size: 13px;
        display: flex;
        align-items: center;
        img{
          margin-right: 2px;
        }
        &--text {
          color: #717579;
          margin-left: 5px;
        }
      }
    }
    .select__control {
      width: 80px;
      background: none !important;
    }
  }

  &__code {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding-bottom: 2px;
  }

  &__container {
    min-height: 36px;
    border-bottom: 1px solid #DADCE0;
    display: flex;
    margin-left: 5px;
    }

  &_input {
  font-size: 16px;
    outline: none;
    padding: 0 10px;
    padding-bottom: 3px;
    border: none;
  }
}
