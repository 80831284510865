@import "src/styles/colors";
.personal-info-patient {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;


  &_profile{
    > span{
      text-align: center;
      margin-top: 16px;
    }
  }
  &_controls {

  }
  &_add-card{
    width: 50%;
    &_header{
      margin-top: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon-component{
        margin-right: 91px;
      }
    }

    &_info{
      margin-top: 44px;
      width: 100%;
      padding: 30px;
      border: 1px solid #DADCE0;
      box-sizing: border-box;
      border-radius: $borderRadius2;

      &_label{
        display: block;
        margin-bottom: 20px;
        line-height: 28px;
      }
      &_form{
        margin-top: 45px;
        &_button{
          display: flex;
          justify-content: flex-end;
        }
        &_container{
          display: flex;

          .custom-input-container{
            width: 180px;
            &:first-of-type{
              margin-right: 8px;
            }
          }
        }
        &_container-full{
          display: flex;
          margin-bottom: 22px;
          .custom-input-container{
            &:first-of-type{
              margin-right: 10px;
            }
            &:last-of-type{
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  &_profile {
    width: 60%;
    display: flex;
    flex-direction: column;

    &_title {
      margin-bottom: 25px;
    }

    &_block-info {
      justify-content: space-between;
      display: flex;

      .custom-input-container {
        width: calc(50% - 5px);
      }

      &_sex {
        flex-direction: column;

        &_title {
          margin-bottom: 20px;
        }

        &_options {
          display: flex;
          margin-bottom: 25px;

          .container-radio-button {
            text-transform: capitalize;
            color: rgba(0, 0, 0, 0.87);

            &:first-of-type {
              margin-right: 32px;
            }
          }
        }
      }

      &.disabled{
        opacity: 0.6;
      }
    }

    &_footer {
      display: flex;
      justify-content: flex-end;

      button {
        &:first-of-type {
          margin-right: 30px;
        }
      }

    }
  }
  &_payment{
    &_item{
      box-sizing: border-box;
      border: 1px solid #E3E3E3;
      align-items: center;
      border-radius: $borderRadius2;
      margin-top: 40px;
      padding: 0 18px 0 13px;
      height: 54px;
      width: 440px;
      display: flex;
      justify-content: space-between;

      &_radio{
        cursor: pointer;
      }

      div{
        align-items: center;
        display: flex;
      }

      &_info{
        display: flex;
        align-items: flex-start !important;
        flex-direction: column;
      }
      img{
        margin-right: 17px;
      }
    }
    &_empty{
      margin-top: 64px;
      display: flex;
      flex-direction: column;
      align-items: center;

      img{
        margin-bottom: 30px;
      }
    }
    &_footer{
      margin-top: 40px;
      justify-content: flex-end;
      display: flex;

      &_central{
        justify-content: center;
      }

      button{
        &:last-of-type{
          width: 170px !important;
          margin-left: 30px;
        }
      }
    }
  }
}


@media screen and (max-width: 1200px) {
  .personal-info-patient{
    &_profile{
      width: 70%;
    }

    &_add-card{
      width: 70%;
    }
  }

}


@media screen and (max-width: 900px) {
  .personal-info-patient{
    &_profile{
      width: 100%;
    }

    &_add-card{
      width: 100% !important;
    }
  }

}

@media screen and (max-width: 576px) {
  .personal-info-patient{
    &_profile{
      padding-top: 40px;
      width: 100%;

      &_footer{
        justify-content: center;
      }

      &_block-info{
        flex-direction: column;

        .custom-input-container{
          width: 100%;
        }
      }

      &_title{
        display: none;
      }
    }

    &_add-card{

      &_header{
        .icon-component{
          margin-right: 40px;
        }
        .typography{
          font-size: 22px;
        }
      }
      &_info{

        .rccs, .rccs__card{
          width: 100%;
        }
        .rccs__cvc{
          left: 63%;
        }
        .rccs__issuer{
          background-size: 50% !important;
        }
        &_label{
          text-align: center;
        }
        &_form{
          &_container{
            flex-direction: column;
          }
          &_container-full{
            flex-direction: column;

            .custom-input-container{
              margin-left: 0 !important;
            }
          }

          .custom-input-container{
            width: 100%;
          }

          &_button{
            button{
              width: 100%;
            }
          }
        }
      }
    }

    &_payment{
      width: 100%;

      &_item{
        width: 100%;
      }

      &_empty{
        > span{
          font-size: 22px;
        }
      }

      &_footer{
        flex-direction: column;

        button{
          width: 100% !important;
          &:last-of-type{
            margin: 0;
            margin-top: 16px;
            width: 100% !important;
          }
        }
      }

    }
  }

}
