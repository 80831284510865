.optional-templates-container_preview-block {
  position: absolute;
  //top: 10000px;
  top: 0;
  left: -700px;
  width: 100vw;
  padding: 50px;
  font-size: 16px;
  z-index: -1;

  *{
    text-align: justify;
  }

  .templates-wrapper {
    position: relative;
    box-sizing: border-box;
    padding: 20px;
    width: 595px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 16px;

    .main-context{
      width: 100%;

      &_br{
        height: 20px;
        width: 100%;
        display: block;
      }
    }
  }

  .line {
    width: 100%;
    height: 1px;
    background: black;
    margin-top: 15px;
  }
  .bold {
    margin-bottom: 15px;
    height: 3px;
  }
  .excuse {
    margin-top: 20px;
  }

  .receipt-context, .covid-context {
    &__sum {
      margin-top: 20px;
    }
    .table-service-wrapper{
      width: 100%;
      padding-top: 20px;
    }
  }

  .discharge-context,
  .visit-note, .receipt-context, .covid-context {
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
    }
    td, th {
      border: 1px solid black;
      text-align: left;
      padding: 4px;
      font-size: 14px;
    }
  }
  .discharge-context__item {
    padding-top: 15px;
  }
  .vital_signs{
    span{ margin-right: 3px}
    &_title{
      font-weight: bolder;
    }
  }
}

.visit-note-pdf-preview{
  padding-bottom: 10px;
  h4{
    margin: 0 0 10px 0;
  }
  span{
    white-space: pre-line;
    margin-right: 3px;
  }
}

.covid-pdf-txt{
  margin-top: 20px;
}

.diagnose-emr-preview, .medication-emr-preview{
  margin-bottom: 20px;
}
.info-block {
  word-break: break-all;
}
.table-td-str-tags{
  display: flex;
  flex-wrap: wrap;
  &-span{
    margin-right: 5px;
  }
}
