@import "src/styles/colors";

.symptom {
  padding: 2px 15px;
  display: flex;
  color: $core100;
  align-items: center;
  min-height: 32px;
  background: $primary20;
  border-radius: 16px;
  line-height: 28px;
}
