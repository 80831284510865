.half-width {
  width: calc(50% - 5px)
}

.doctor-page-personal-info_title-block .drop-down-wrapper .drop-down__value-container .drop-down__single-value {
  color: black;
}

.doctor-page-personal-info_radio-group {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: -20px;
}
