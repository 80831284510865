.delta {
  display: flex;
  align-items: center;

  &.delta_zero{display: none}

  .mgr {
    margin: 0 3px;
  }

  .image {
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;

    &.positive {
      background-image: url("../../../dist/images/common/icons/arrow-up.svg");

    }

    &.negative {
      background-image: url("../../../dist/images/common/icons/arrow-down.svg");
    }
  }
}
