.price-block{
  display: flex;
  flex-direction: column;

  &_gift{
    grey{
      color: rgb(113, 117, 121);
    }
  }

  > div{
    margin-bottom: 15px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    &:last-of-type{
      margin-bottom: 0;
    }
  }

  &_type{
    div:nth-of-type(1){
      margin-right: auto;
    }
    > span:nth-of-type(2){
      margin-left: 80px;
    }
  }
}

@media(max-width: 576px) {
  .price-block{
    &_type{
      div:nth-of-type(1){
        margin-right: auto;
      }
      > span:nth-of-type(2){
        margin-left: 20px;
      }
    }
  }
}
