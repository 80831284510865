.patients-report {
  max-width: 360px;
  width: 100%;

  &__filters {
    max-width: 360px;
    width: 100%;
  }

  button {
    width: 100%;
  }
}
