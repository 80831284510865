@import "src/styles/colors";

.file-attachment{
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  &_actions{
    width: 137px;
    display: flex;
  }

  .menu-list {
    li{
      div {
        margin-right: 19px;
      }
    }
  }

  &_container{
    width: calc(100% - 136px - 58px);
    display: flex;
    flex-direction: column;
  }


  &_cancel, &_save{
    position: absolute;
    right: 0;
    font-size: 12px;
    top: 9px;
    text-transform: uppercase;
    color: $primary80;
  }
  &_cancel{

  }
  &_save{
    right: 60px;
  }

  &_edit{
    border: none;
    font-size: 15px;
    background: #E8E8E8;
    padding: 5px;
    display: inline-block;
    width: calc(100% - 20px);
    white-space: nowrap;
    border-radius: 4px;
  }

  &_file{
    margin-right: 16px;
    margin-left: 4px;
    display: flex;
    position: relative;
    &_format{
      position: absolute;
      left: -5px;
      bottom: 8px;
      background: $primary80;
      padding: 0 2px;
      border-radius: 2px;
      text-transform: uppercase;
      font-size: 14px;
      color: white;
    }
  }
  &_name{
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
