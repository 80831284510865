.reports {
  margin-top: 40px;
  &__control {
    justify-content: flex-start;

    .doctor-page-personal-btn-container {
      justify-content: flex-start;
    }
  }
  &__filters {
    margin-top: 50px;
  }

  .gift-cards-container {
    padding: 0;
  }
}
