/*
.container-add-new-doctor{

  &_buttons{margin-top: 64px;
    margin-bottom: 24px;
    display: flex;
    justify-content: flex-end;

    button{
      &:first-of-type{
        margin-right: 15px;
      }
    }
  }
  &_title{
    display: flex;
    justify-content: center;
    margin-top: 70px;
    margin-bottom: 64px;
  }
  &_row{
    display: flex;
    &_right, &_left{
      padding:  0 10%;
      width: 50%;
    }
    &_right{
      align-items: flex-start;
      display: flex;
      flex-direction: column;

      &_date{
        margin-top: 16px;
      }
    }
    &_left{

      &.container-link-doctor{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &_send-invitation{
        background: none;
        outline: none;
        padding: 0;
        border: none;
        cursor: pointer;
      }
    }
  }
  &_buttons{

  }
  &_upload{
    cursor: pointer;
    letter-spacing: 1.25px;
    margin-top: 16px;
    background: none;
    border: none;
    padding: 0;
    position: relative;
    color:#296193;
    //background-color: #757575;
    cursor: pointer;
    &_input{
      cursor: pointer;
      width: 100%;
      height: 100%;
      position: absolute;
      opacity: 0;
    }

  }
}


.upload-component-custom{
  display: flex;
  height: 36px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  &_file{
    width: 50%;
    align-items: center;
    padding: 8px 12px 8px 8px;
    border-radius: 4px;
    background: #F2F8FD;
    display: flex;
    justify-content: space-between;
    &_name{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 70%;
    }
    &_icons {
      align-items: center;
      display: flex;
      &_view {
        margin-right: 30px;
      }
      &_download {

      }
    }
  }
  &_buttons{
    button{
      cursor: pointer;
      outline: none;
      border: none;
      background: none;
      padding: 0;
    }
    &_decline{
      margin-right: 24px;

    }
    &_accept{

    }
  }
}

//micro cap version - 9 10*/

.container-add-new-doctor{
  width: 62%;
  margin: 0 auto;

  &_title{
    display: block;
    margin: 40px 0 43px 0;
  }
  &_title-block {
    display: flex;
    justify-content: space-between;

    .custom-input-container{
      &:first-of-type {
        margin-right: 10px;
      }
    }
  }

  &_title-btn {
    display: flex;
    margin-left: auto;
    justify-content: space-between;
    width: 278px;
    margin-bottom: 32px;
  }

  &__btn {
    margin-top: 40px;
  }
}

.archive-popup {
  font-size: 34px;
  line-height: 36px;
  &:last-of-type {
    margin-bottom: 36px;
  }
}

//.archive-underline:first-child {
//  border-bottom: 1px solid #979797;
//}
