.daily-report {
  max-width: 360px;
  width: 100%;

  &-filters {
    display: flex;
    flex-direction: column;
  }

  &__datepicker {
    margin-bottom: 25px;
  }

  .custom-date-range-picker {
    margin-top: 21px;
  }

  &__dropdowns {
    max-width: 360px;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  button {
    width: 100%;
  }
}
