$primary80: #1E427B;
$primary70: #2758A4;
$primary100: #0C1A31;
$primary10: #F3F6FC;
$primary20: #E6ECF9;
$primary50: #6390D9;
$primary60: #417AD2;
$primary40: #9CB9E7;
$primary30: #CEDCF3;


$core30: #DDE0E4;
$core20: #F4F5F6;
$core40: #BAC0C9;
$core50: #98A1AE;
$core100: #090A0B;
$core70: #5E6878;
$core80: #434A56;
$core10: #F9FAFB;


$borderRadius2: 2px;
$borderRadius4: 4px;
$borderRadius8: 8px;
$borderRadius10: 10px;


$primary: #1771B7;
$primaryOLD40: rgba($primary, 0.4);

$error: #F86161;
$error40: rgba($error, 0.4);


$shadowDropdown: 0px 4px 16px 4px rgba(39, 88, 164, 0.08);
$itemRequestshadow: 0px 2px 12px 2px rgba(65, 122, 210, 0.20);
$shadowActive: 0px 4px 16px 4px rgba(39, 88, 164, 0.08);
$shadowCard: 0px 1px 8px rgba(14, 67, 109, 0.16);
$shadowNotification: 0px 2px 24px rgba(30, 66, 123, 0.14);
$shadowPopup: 0px 4px 20px rgba(9, 45, 73, 0.12);
$shadowSidebar: -2px 0px 8px rgba(16, 78, 127, 0.16);
$shadowBottomBar: 0px -2px 8px rgba(9, 68, 114, 0.08);
$shadowTopBar: 0px 2px 8px rgba(9, 68, 114, 0.08);

$gold: #CB9A2A;
$pinch: #D3970D;
$error-dark: #CE1212;
$error-light-1: #FDC4C4;
$warning: #F3BD41;
$warningLight: #FBEBC5;
