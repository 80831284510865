@import "src/styles/colors";
.trash-custom:hover {
  fill: rgba(255, 0, 0, 0.6);
}
.archive-custom:hover {
  svg {
    path {
      fill: $primary80;
    }
  }
}

.circular_btn-container {
  display: flex;
  &_btn {
    cursor: pointer;
  }
  & .none {
    display: none;
  }
}
