.container-with-actions{
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;

  .button-menu{
    display: none;
    .menu-list{
      top: 30px;
    }
  }

  &_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &_body{
    margin-top: 5px;
  }
  &_footer{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    button {
      display: flex;
      align-items: center;
      min-width: 124px;
      width: auto;

      svg {
        width: 14px;
        height: 14px;
      }
    }

    .icon-component{
      margin-right: 8px
    }

    .info-cloud__top {
      top: calc(100% - 90px);
      left: 40px;
      min-width: 100%;

      &::before {
        top: 50px
      }
    }
  }
  .follow-ups-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .container{
      width: calc(100% - 40px);
      margin-top: 20px;
      height: 36px;

    }
    &_menu{
      display: none;
      a{
        text-decoration: none;
      }
    }
    .container_label{
      max-width: calc(100% - 20px);
      position: relative;
      top: -8px;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 8px 16px;
      background: #F8F8F8;
      border-radius: 4px;
    }

    &:hover{
      .button-menu{
        display: inline-block;
      }
    }
  }

  .attachment-item{
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;

    &:hover{
      .button-menu{
        display: inline-block;
      }
    }
    &:first-of-type{
      margin-top: 15px;
    }
  }
}
