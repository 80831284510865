@import "src/styles/colors";
.step-symptoms-list {
  width: 100%;

  .links-from-list-symptoms{
    line-height: 24px;
    letter-spacing: 0.15px;
    a{
      color: #1771B7 !important;
    }
  }

  .list-symptoms-checkbox{
    margin-bottom: 65px;
  }

  &_title{
    margin-bottom: 40px;
  }

  &_label{

  }

  &_wrapper-other-symptoms{
    padding: 10px;
    padding-left: 0;

    &_textarea{
      margin-top: 12px;
    }
  }
  &_wrapper {
    max-height: calc(100vh - 383px);
    //min-height: 400px;
    padding: 10px;
    padding-left: 2px;
    padding-top: 2px;
    overflow: auto;

    &_isTextarea{
      max-height: calc(100vh - 520px);
    }
    &_data-item {
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #DADCE0;
      border-radius: 2px;
      padding: 8px 21px 8px 18px;
      display: flex;
      margin-top: 12px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      &[aria-disabled='true']{position: relative;
        cursor: not-allowed;
        pointer-events: none;
        &:after{
          position: absolute;
          height: 100%;
          width: 100%;
          background-color: rgba($primary80, 0.2);
          content: '';
          top: 0;
          left: 0;

        }
      }

      &_selected{
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
        border: none
      }

      &_wrapper {
        display: flex;
        align-items: center;

        &_name {
          margin-left: 20px;
          padding-right: 10px;
        }

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
        }
      }

      &:first-of-type {
        margin-top: 0;
      }
    }
  }
}

.container-symptoms-not-treat{
  ol{
    padding: 0 0 0 15px;

    li{
      margin-top: 20px;
      &:first-of-type{
        margin-top: 0;
      }
    }
  }

  &_note{
    margin-bottom: 40px;
    margin-top: 30px;
  }
}

@media (max-width: 1200px) {
  .step-symptoms-list_wrapper {
    padding: 0;
    max-height: initial;
    overflow: initial;

    &-other-symptoms {
      padding: 0;
    }
  }
}
