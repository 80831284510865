@import "src/styles/colors";

.date-time-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  overflow-y: auto;
  padding: 12px 20px;

  &_img {
    width: 214px;
    height: 214px;
    margin-bottom: 40px;
  }

  &_notes {

  }

  .textarea-common-component {
    width: 100%;
  }

  &_date {
    width: 100%;
    display: flex;
    grid-gap: 8px;
  }

  .time-slot__container {
    width: 100%;
  }

  &_address {
    display: flex;
    width: 100%;
    grid-gap: 16px;
    padding-top: 40px;
    margin-top: 40px;
    border-top: 1px solid $core20;
  }

  &_additional-info {
    display: flex;
    width: 100%;
    grid-gap: 16px;
  }
}
