.patient-data-container{
  width: 65%;
  height: 100%;

  &_controls{
    display: flex;
    padding: 30px 0 40px 30px;
  }

  &_data-patient-request{
    padding-bottom: 30px;
    display: flex;
    width: 100%;
    height: calc(100% - 112px);
  }
}
