@import 'src/styles/colors';
.container-custom-header {
  display: flex;
  justify-content: space-between;
  height: 64px;
  align-items: center;
  border-bottom: 1px solid #DADCE0;
  padding: 0 24px;
  box-sizing: border-box;
  position: relative;
  z-index: 1003;

  &_img{
    cursor: pointer;
  }

  &_tabs {
    display: flex;

    &_item {
      cursor: pointer;
      font-weight: 500;
      font-size: 18px;
      line-height: 56px;
      margin-right: 40px;
      color: $core70;
      text-transform: capitalize;
      text-decoration: none;

      &:last-of-type {
        margin-right: 0;
      }

      &-active {
        color: $primary70;
      }
    }
  }

  &_right-block {
    display: flex;
    align-items: center;

    &_notifications{

    }
    &_bell{

    }

    &_account-menu-content{
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: space-between;
      align-items: flex-end;
      &_personal-info{
        width: 100%;
        display: flex;
        &_img{
          margin-right: 16px;
          width: 46px;
          height: 46px;
          border-radius: 50%;
        }
        &_wrapper-text{
          justify-content: center;
          display: flex;
          flex-direction: column;
          &_initials{

          }
          >button{
            margin-left: -8px;
            width: fit-content;
          }
          > span {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
        }
      }
      &_container-button{
        display: flex;
        justify-content: flex-end;
        &_button{

        }
      }

      &_button{
        outline: none;
        border: none;
        background: none;
        padding: 0;
        color: white;
        display: flex;
        align-items: center;
        flex-direction: column;
      }
      &_icon{

      }
    }
    &_bell {
      margin-right: 16px;
    }
  }
}

.container-custom-header_right-block_account-menu-arrow{
  display: none;
}

.containers-popup-details-user{
  top: 54px;
  right: 24px;
  background-color: white;
  border-radius: 2px;
  width: 300px;
  height: 146px;
  position: absolute;
  box-shadow: $shadowPopup;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  box-sizing: border-box;
}
