@import "src/styles/colors";
.patient-sidebar {
  display: flex;
  flex-direction: column;
  padding: 24px 20px;
  border-radius: $borderRadius10;
  border: 1px solid #DADCE0;

  &--completed {
    border-color: rgba(33, 150, 83, 0.4);
  }

  &__button.btn-component {
    margin-top: 24px;
    align-self: flex-end;
  }

  .separator {
    width: 100%;
    margin: 30px 0;
  }

  .container-sign_signature-file {
    margin: 0;
    padding: 0;
    border: none;
  }

  .container-sign_signature,
  .add-services,
  .side-bar__patient-comment,
  .textarea-common-component,
  .additional-services {
    margin: 0 !important;
  }

  .additional-services .additional-services_item:first-of-type {
    margin-top: 0;
  }

  .view-services {
    margin-bottom: 16px;
  }

  .textarea-common-component textarea {
    margin-bottom: 0;
  }

  .container-sign_signature {
    padding: 0;
    border: 0;
  }
}
