@import "src/styles/colors";
.doctor-page-schedule{
  &_radiobuttons{
    padding-left: 20px;

    .container-radio-button{
      &:first-of-type{
        margin-bottom: 20px;
      }
    }
  }
}

.shedule-container-event-doctor{
  overflow: hidden;
  padding: 6px;
  height: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  &_is-mess{
    background: #F2BD42;
    position: absolute;
    top: 12px;
    left: 8px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }

  &_header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 12px;
    line-height: 20px;
  }

  &_icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
  }

  &_status{
    text-align: right;
    width: 100%;
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &_patient{
    font-weight: 800;
    width: 100%;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &_amount {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    font-size: 14px;
    line-height: 20px;

    &-wrapper {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &_not-selected {
    opacity: 0.4;
  }
  &_is-pending{
    background: $primary20 !important;
    border: 1px solid $primary80 !important;
    color: $primary80 !important;
  }
  &_is-my {
    background-color: $primary80;
    color: #FFFFFF;
    border: 1px solid $primary30;
  }
  &_assigned-not-my{
    background: $gold;
    color: white;
  }
  &_is-not-assigned{
    background: $error-light-1;
    border: 1px solid $error-dark;
    color: $error-dark;
  }

  &_is-minified{
    padding: 2px 6px;
    justify-content: center;
    .shedule-container-event-doctor_patient {
      font-size: 12px;
      line-height: 12px;
    }
  }

  &_declined{
    background: #DADCE0;
    color: black;
    border: 1px solid #757575;
    .shedule-container-event-doctor_status{
      color: rgba(255, 0, 0, 0.6);
    }
  }
  &:hover{
    box-shadow: $shadowActive;
  }
}

