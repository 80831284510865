.request-status {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 115px;
  width: 100%;
  padding: 7px 23px;
  font-size: 14px;
  border-radius: 20px;
}
