@import "src/styles/colors";
.container-pmh{
  width: 100%;
  //height: calc(100% - );
  display: flex;
}

.medicastion-view{
margin-top: 25px;

  &_footer{
    display: flex;
    justify-content: space-between;
  }
  .doctor-page-personal-btn-container{
    margin-top: 12px;
    margin-bottom: 8px;
  }
  &_item{
    width: calc(100% + 60px);
    transform: translateX(-30px);
    padding: 4px 25px 4px 31px;

    display: flex;
    flex-direction: column;
    &:nth-of-type(2n){
      background: $primary10;
    }

    &_header{
      align-items: center;
      display: flex;
      justify-content: space-between;

      &_container{
        width: 70%;
        word-break: break-all;
        display: flex;
        align-items: center;
      }
      &_date{
        margin-left: 18px;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #717579;
      }
    }
    &_point{
      display: flex;
      width: 6px;
      height: 6px;
      min-width: 6px;
      min-height: 6px;
      border-radius: 100%;
      background: #F2BD42;

      &_active{
        background: #219653;
      }
    }
    &_name{margin-left: 12px;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: $core100;
    }
    &_doze{
      text-align: right;
      width: 30%;
      word-break: break-all;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $core80;
    }
  }
  &_button{
    margin-top: 8px;
    display: flex;
    align-items: center;

    &_txt{
      margin-left: 14px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.75px;
      text-transform: uppercase;
      color: #1771B7;
    }
    .icon-component{

      transform: rotate(90deg);

      path{
        fill: #1771B7;
      }
    }

    &_active{
      .icon-component{
        transform: rotate(-90deg);
      }
    }
  }
}
