.custom-notification-toaster{
  &_container-info{
    display: flex;
    &_img{
      margin-right: 20px;
    }
    &_block-text{
      &_title{

        font-size: 16px;
        line-height: 20px;
        text-transform: capitalize;
        color: #717579;
        display: flex;
        justify-content: space-between;

        path{
          fill: #5F6368;
        }

      }
      &_text{
        margin-top: 15px;
        font-size: 18px;
        line-height: 24px;
        text-transform: capitalize;
        color: #000000;
      }
    }

  }
  &_button-navigate{
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }
}
